

module.exports = {
  formatWordpressObject(obj, acfConfig) {
    Object.entries(obj).forEach(([key, value]) => {
      if (value && value.rendered !== undefined) {
        obj[key] = value.rendered
      }
    })

    obj.menu_order = parseInt(obj.menu_order)
    obj.yoast_json_ld = obj.yoast_json_ld ? JSON.stringify(obj.yoast_json_ld[0]) : ''
    obj.thumbnail = obj.thumbnail || null

    if (acfConfig) {
      const acfTypes = this.groupAcfTypes(acfConfig)
      obj.acf = this.formatACFObject(obj.acf, acfTypes)
    }

    return obj
  },

  formatACFObject(obj, acfTypes) {
    if (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        obj[key] = this.formatACFValue(key, value, acfTypes)
      })
    }
    return obj
  },

  formatACFValue(key, value, acfTypes) {
    if (value === '' || value === false) {
      switch (acfTypes[key]) {
        case 'text':
        case 'wysiwyg':
        case 'textarea':
        case 'color_picker':
        case 'url':
          return ''
        case 'image':
          return null
        case 'repeater':
          return []
        case 'true_false':
          return false
        default:
          return null
      }
    } else if (Array.isArray(value)) {
      return value.map(item => this.formatACFObject(item, acfTypes))
    } else {
      return value
    }
  },

  groupAcfTypes(acfConfig) {
    let types = {};
    acfConfig.forEach(groupConfig => {
      types = getTypesFromFields(groupConfig.fields, types)
    })
    return types
  }
}

function getTypesFromFields(fields, types) {
  fields.forEach(fieldConfig => {
    types[fieldConfig.name] = fieldConfig.type
    if (fieldConfig.sub_fields) {
      types = getTypesFromFields(fieldConfig.sub_fields, types)
    }
  })
  return types
}
