import React, { useEffect, useState } from "react"
import { get } from "lodash"
import ReactRating from "react-rating"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import Translations from "../domain/translations"
import { showSuccess, showError } from "../domain/notifications"

const getRating = async (post_id) => {
  return await axios.get(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/ratings?post_id=${post_id}`)
}

const sendRating = async (post_id, rating) => {
  try {
    const recaptcha_token = await recaptchaRef.current.execute()

    await axios.post(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/ratings`, {
      recaptcha_token,
      post_id: post_id,
      rating: rating
    })
    showSuccess("Revisão enviada!", "Obrigado.")
  } catch (e) {
    const message = get(e, "response.data.message") || Translations.notifications.try_later
    showError(Translations.notifications.error_message, message)
  }
}

const loadRating = async (post_id, setRatting) => {
  try {
    const response = await getRating(post_id)
    console.log("loadRating", response)
    const rating = response.data ? response.data.rating : 0
    setRatting(rating)
  } catch (e) {
    console.error("loadRating", e)
  }
}

const recaptchaRef = React.createRef()

const Rating = ({ post }) => {
  const [ratting, setRatting] = useState(0)
  const [readonly, setReadonly] = useState(false)

  useEffect(() => {
    loadRating(post.post_id, setRatting)
  }, [post.post_id])

  const submit = async (value) => {
    setReadonly(true)
    sendRating(post.post_id, value)
    setRatting(value)
  }

  return (
    <div className="is-margin-vertical-7">
      <div className="columns is-mobile is-gapless">
        <div className="column is-narrow">
          <div className="is-margin-right-6">
            <span className="has-text-weight-bold">
              Avalie a notícia:
          </span>
          </div>
        </div>

        <div className="column is-narrow">
          <div className="is-margin-right-2">
            <ReactRating
              initialRating={ratting}
              emptySymbol={<StarEmpty />}
              fullSymbol={<StarFull />}
              readonly={readonly}
              onClick={submit}
            />

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
            />
          </div>
        </div>

        <div className="column is-narrow">
          <span className="has-text-weight-bold has-text-orange">
            {ratting.toFixed(1)}
          </span>
        </div>
      </div>
    </div>
  )
}

const StarEmpty = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.845" height="16.222" viewBox="0 0 16.845 16.222" style={{ marginRight: 2 }}>
    <g transform="translate(-7.468 -8.324)">
      <path style={{ fill: "none", stroke: "#fa880c", strokeWidth: "2px" }} d="M16.144,11.442l3.2.461a1.106,1.106,0,0,1,.615,1.886l-2.313,2.26a1.106,1.106,0,0,0-.317.98l.55,3.186a1.106,1.106,0,0,1-1.6,1.168l-2.864-1.5a1.106,1.106,0,0,0-1.03,0l-2.86,1.508a1.106,1.106,0,0,1-1.607-1.164l.543-3.188a1.106,1.106,0,0,0-.319-.979L5.822,13.806a1.106,1.106,0,0,1,.611-1.888l3.2-.469a1.106,1.106,0,0,0,.832-.606l1.428-2.9a1.106,1.106,0,0,1,1.984,0l1.435,2.9A1.106,1.106,0,0,0,16.144,11.442Z" transform="translate(3 2)" />
    </g>
  </svg>

const StarFull = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.844" height="16.221" viewBox="0 0 16.844 16.221" style={{ marginRight: 2 }}>
    <g id="star-empty" transform="translate(-7.468 -8.326)">
      <path id="Path_1" data-name="Path 1" d="M16.144,11.442l3.2.461a1.106,1.106,0,0,1,.615,1.886l-2.313,2.26a1.106,1.106,0,0,0-.317.98l.55,3.186a1.106,1.106,0,0,1-1.6,1.168l-2.864-1.5a1.106,1.106,0,0,0-1.03,0l-2.86,1.508a1.106,1.106,0,0,1-1.607-1.164l.543-3.188a1.106,1.106,0,0,0-.319-.979l-2.32-2.254a1.106,1.106,0,0,1,.611-1.888l3.2-.469a1.106,1.106,0,0,0,.832-.606l1.428-2.9a1.106,1.106,0,0,1,1.984,0l1.435,2.9A1.106,1.106,0,0,0,16.144,11.442Z" transform="translate(3 2)" fill="#fa880c" stroke="#fa880c" strokeWidth="2" />
    </g>
  </svg>


export default Rating
