import React from "react"
import BlogSlide from "./blog-slide"

const BlogRelation = ({ posts }) => {
  if (!posts || posts.length === 0) return null

  return (
    <section className="product-relation-component">
      <div className="container">
        <div className="has-text-centered is-margin-bottom-8">
          <h2 className="title is-1">
            Notícias relacionadas
          </h2>
        </div>
        <div className="is-margin-bottom-6">
          <BlogSlide posts={posts} />
        </div>
      </div>
    </section>
  )
}

export default BlogRelation
