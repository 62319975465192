import React, { useEffect, useState } from "react"
import { get } from "lodash"
import axios from "axios"
import emailValidator from "email-validator"
import { Form } from 'react-final-form'
import { LazyLoadImage } from "react-lazy-load-image-component"
import ReCAPTCHA from "react-google-recaptcha"
import moment from "../domain/moment"
import { showSuccess, showError } from "../domain/notifications"
import Translations from "../domain/translations"
import { InputField, TextareaField } from "./fields"

const emailValid = value => (emailValidator.validate(value) ? undefined : Translations.form.errors.email)
const required = value => (value ? undefined : Translations.form.errors.required)

const getStorageItem = (name) => window.localStorage.getItem(name)

const buildInitialValues = () => ({
  author_name: getStorageItem('author_name'),
  author_email: getStorageItem('author_email'),
  content: "",
  reset: Math.random()
})

const getComments = async (postId) => {
  return await axios.get(`${process.env.WORDPRESS_BASE_URL}/wp/v2/comments?post=${postId}`)
}

const onSubmit = async (post_id, parent, values, resetForm) => {
  try {
    window.localStorage.setItem('author_name', values.author_name)
    window.localStorage.setItem('author_email', values.author_email)
    const recaptcha_token = await recaptchaRef.current.execute()

    await axios.post(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/comments`, {
      ...values,
      recaptcha_token,
      post: post_id,
      parent: parent
    })
    showSuccess("Comentário enviado!", "Obrigado.")
    resetForm()
  } catch (e) {
    const message = get(e, "response.data.message") || Translations.notifications.try_later
    showError(Translations.notifications.error_message, message)
  }
}

const loadComments = async (post_id, setComments) => {
  try {
    const response = await getComments(post_id)
    console.log("loadComments", response)
    setComments(response.data)
  } catch (e) {
    console.error("loadComments", e)
  }
}

const recaptchaRef = React.createRef()

const Comments = ({ post }) => {
  const [comments, setComments] = useState([])

  const loadCommentsFunction = () => loadComments(post.post_id, setComments)

  useEffect(() => {
    loadComments(post.post_id, setComments)
  }, [post.post_id])

  return (
    <div className="comments-component">
      <div className="container-content">
        <div className="is-margin-bottom-8">
          <h2 className="has-text-black is-size-3 has-text-weight-bold">
            Comentários
          </h2>
        </div>

        <CommentList comments={comments} level={0} loadComments={loadCommentsFunction} />
        <CommentForm post_id={post.post_id} loadComments={loadCommentsFunction} />
      </div>
    </div>
  );
}

const CommentList = ({ comments, parent, level, loadComments }) => {
  if (parent === undefined) parent = 0

  return comments
    .filter(comment => comment.parent === parent)
    .map(comment => <CommentItem key={comment.id} comments={comments} comment={comment} level={level} loadComments={loadComments} />)
}

const CommentItem = ({ comments, comment, level, loadComments }) => {
  const [showForm, setShowForm] = useState(false)

  return (
    <div>
      <div className="box comment-item-component">
        <article className="media" style={{ marginLeft: level * 20 }}>
          <div className="media-left">
            <figure className="image is-48x48 is-round-2">
              <LazyLoadImage className="is-rounded" src={get(comment, "author_avatar_urls.48")} alt={comment.author_name} />
            </figure>
          </div>
          <div className="media-content">
            <h4 className="has-text-black is-size-5 has-text-weight-bold">
              {comment.author_name}
            </h4>
            <div className="content" dangerouslySetInnerHTML={{ __html: get(comment, "content.rendered") }}>
            </div>
            <nav className="level is-mobile">
              <div className="level-left">
                <span className="level-item">
                  {moment(comment.date).format("DD MMM YYYY HH:mm")}
                </span>
                <button className="level-item" onClick={() => setShowForm(!showForm)}>
                  <span className="has-text-weight-bold has-text-blue is-font-size-16">Responder</span>
                </button>
              </div>
            </nav>
          </div>
        </article>

        {showForm && <CommentForm post_id={comment.post} parent={comment.id} loadComments={loadComments} />}
      </div>

      <CommentList comments={comments} parent={comment.id} level={level + 1} loadComments={loadComments} />
    </div>
  )
}

const CommentForm = ({ post_id, parent, loadComments }) => {
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    setInitialValues(buildInitialValues())
  }, [post_id, parent])

  const resetForm = () => {
    setInitialValues(buildInitialValues())
    loadComments()
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(post_id, parent, values, resetForm)
      }}
      render={({ handleSubmit, submitting }) => (
        <div className="comment-form-component">
          <form
            onSubmit={handleSubmit}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
            />

            <div className="columns">
              <div className="column">
                <InputField
                  name="author_name"
                  placeholder="Nome"
                  validate={required}
                />
              </div>
              <div className="column">
                <InputField
                  name="author_email"
                  placeholder="E-mail"
                  validate={emailValid}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <TextareaField
                  name="content"
                  placeholder="Sua mensagem"
                  validate={required}
                />
              </div>
            </div>

            <div>
              <button type="submit" disabled={submitting} className={`button is-primary is-rounded ${submitting && 'is-loading'}`}>
                <span className="icon is-small">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane"
                    className="svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>
                </span>
                <span className="has-text-weight-bold">Enviar</span>
              </button>
            </div>
          </form>
        </div>
      )}
    />
  )
}

export default Comments
