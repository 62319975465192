import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import BlogSingle from "../components/blog-single"
import BlogRelation from "../components/blog-raletion"
import NewsContent from "../components/news-content"
import HomeContact from "../components/home-contact"
import Router from "../domain/router"

const Page = ({ data }) => {
  const mainPage = data.mainPage
  const currentPost = data.currentPost
  const relatedPosts = data.relatedPosts.nodes
  const link = Router.blogPath("news")

  return (
    <Layout>
      <SEO post={currentPost} />
      <PageBanner link={link} post={mainPage} />
      <NewsContent>
        <BlogSingle post={currentPost} />
      </NewsContent>
      <BlogRelation posts={relatedPosts} />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!, $primary_news_category_id: Int) {
    currentPost: wordpressNews(id: {eq: $id }) {
      ...NewsFull
    }

    relatedPosts: allWordpressNews(
      limit: 10,
      filter: {id: {nin: [$id]}, primary_news_category_id: {eq: $primary_news_category_id}, status: {eq: "publish"}},
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        ...NewsItem
      }
    }

    mainPage: wordpressMainPage(name: {eq: "news-superbom"}) {
      ...MainPageFull
    }
  }
`
