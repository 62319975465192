import React, { useState } from "react"
import { get } from "lodash"
import gravatar from "gravatar"
import { Link } from "gatsby"
import LazyLoad from "react-lazy-load"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"
import moment from "../domain/moment"
import Comments from "./comments"
import Rating from "./rating"

const clearShareText = (value) => encodeURI(value).split('#').join('%23')

const BlogSingle = ({ post }) => {
  const [contentInfoActive, setContentInfoActive] = useState(false);

  return (
    <section className="blog-single-component post-single-component">
      <div className={`content-info ${contentInfoActive ? null : 'hidden'}`}>
        <div className="is-margin-bottom-6">
          <h1 className="link-hover has-text-black is-size-1 has-text-weight-bold">
            {post.title}
          </h1>
        </div>

        <ToolbarTop post={post} />

        <div className="is-margin-bottom-8">
          <LazyLoad>
            <figure className="image is-2by1 image-box with-fix-width is-round-2 has-background-black">
              <img src={Thumbnail.postSingleImage(post)} alt={post.title} />
            </figure>
          </LazyLoad>
        </div>

        <div className="is-margin-bottom-8">
          <div className="content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>

        <ToolbarBottom post={post} />

        <hr />

        <Rating post={post} />
        <Comments post={post} />
        <div className="content-info-cover"></div>
      </div>

      <div className="content-info-button has-text-centered is-margin-top-4">
        {!contentInfoActive && <button className={`button is-rounded`} onClick={() => setContentInfoActive(true)}>
          <strong>Leia mais</strong>
        </button>}
      </div>
    </section >
  )
}

const ToolbarTop = ({ post }) => (
  <div className="is-margin-bottom-6">
    <div className="columns is-vcentered is-desktop">
      <div className="column">

        <div className="columns is-vcentered">

          {post.author &&
            <>
              <div className="column is-narrow">
                <div className="columns is-vcentered is-gapless is-mobile">
                  <div className="column is-narrow">
                    <Link to={Router.blogAuthorPath(post.type, post.author.slug)} className="is-margin-right-2 is-block">
                      <figure className="image is-48x48 is-round-2">
                        <img className="is-rounded" src={gravatar.url(post.author.email)} alt={post.author.name} />
                      </figure>
                    </Link>
                  </div>
                  <div className="column">
                    <Link to={Router.blogAuthorPath(post.type, post.author.slug)} className="has-text-weight-bold has-text-black">
                      {post.author.name}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="column is-narrow is-hidden-mobile">|</div>
            </>}

          <div className="column is-narrow">
            <div className="columns is-vcentered is-mobile is-variable is-1">

              {post.primary_category &&
                <>
                  <div className="column is-narrow">
                    <Link to={Router.blogCategoryPath(post.type, post.primary_category.slug)} className="has-text-weight-bold has-text-blue">
                      {post.primary_category.name}
                    </Link>
                  </div>
                  <div className="column is-narrow">&bull;</div>
                </>}

              <div className="column is-narrow">
                <span>{moment(post.date).format("DD MMM YYYY")}</span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="column is-narrow">
        <ShareTool post={post} />
      </div>
    </div>
  </div>
)

const ToolbarBottom = ({ post }) => {
  const tags = post.post_tag || post.news_tag || []

  return (
    <div className="is-margin-bottom-6">
      <div className="columns is-desktop">
        <div className="column">
          {tags.map(tag => (<TagItem key={tag.term_id} type={post.type} tag={tag} />))}
        </div>
        <div className="column is-narrow">
          <ShareTool post={post} />
        </div>
      </div>
    </div>
  )
}

const ShareTool = ({ post }) => {
  const postUrl = process.env.FRONTEND_BASE_URL + Router.blogPostPath(post)
  const title = post.title
  const text = `${title} ${postUrl}`
  const primary_category = 'category-' + get(post, "primary_category.slug")

  return (
    <div className="columns is-vcentered is-mobile">
      <div className="column is-narrow">
        <span className="is-size-6 has-text-grey">Compartilhe</span>
      </div>
      <div className="column is-narrow">
        <div className="columns is-variable is-1 is-mobile">
          <div className="column is-narrow">
            <a href={`http://www.facebook.com/sharer.php?u=${postUrl}&t=${clearShareText(title)}`} id="social-share-facebook" target="_blank" rel="noopener noreferrer" className={`facebook icon-button is-primary is-outlined ${primary_category}`}>
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                className="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
            </a>
          </div>
          <div className="column is-narrow">
            <a href={`http://twitter.com/intent/tweet?text=${clearShareText(text)}`} id="social-share-twitter" target="_blank" rel="noopener noreferrer" className={`twitter icon-button is-primary is-outlined ${primary_category}`}>

              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                className="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
            </a>
          </div>
          <div className="column is-narrow">
            <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${postUrl}&title=${clearShareText(title)}`} id="social-share-linkedin" target="_blank" rel="noopener noreferrer" className={`linkedin icon-button is-primary is-outlined ${primary_category}`}>
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                className="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const TagItem = ({ type, tag }) => (
  <Link to={Router.blogTagPath(type, tag.slug)} className="button is-outlined is-small is-margin-right-2 is-margin-bottom-2">
    <span className="has-text-weight-bold has-text-dark">
      {tag.name}
    </span>
  </Link>
)

export default BlogSingle
