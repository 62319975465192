import React from "react"
import { get } from "lodash"
import Slick from "react-slick"
import { Link } from "gatsby"
import LazyLoad from "react-lazy-load"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"
import moment from "../domain/moment"

const BlogSlide = ({ posts }) => {
  if (!posts) return null

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className="blog-slide-component slick-default-dots">
      <Slick {...settings}>
        {posts.map(post => {
          const postLink = Router.blogPostPath(post)
          const primary_category = 'blog-slide category-' + get(post, "primary_category.slug")

          return (
            <div key={post.post_id} className="blog-item is-padding-4">
              <div className="is-margin-bottom-4">
                <Link to={postLink} className={primary_category}>
                  <LazyLoad>
                    <figure className="image is-2by1 image-box with-fix-width is-round-2 has-background-black">
                      <img src={Thumbnail.postGridImage(post)} alt={post.title} />
                    </figure>
                  </LazyLoad>
                </Link>
              </div>

              <div className="is-margin-bottom-2">
                <Link to={postLink} className={`link-hover has-text-black is-size-5 has-text-weight-bold ${primary_category}`}>
                  {post.title}
                </Link>
              </div>

              <div className="is-margin-bottom-4">
                <div className="columns is-vcentered">
                  {post.primary_category &&
                    <div className="column is-narrow">
                      <Link to={Router.blogCategoryPath(post.primary_category.slug)} className={`has-text-weight-bold has-text-blue ${primary_category}`}>
                        {post.primary_category.name}
                      </Link>
                    </div>}

                  <div className="column is-narrow">
                    <span>{moment(post.date).format("DD MMM YYYY")}</span>
                  </div>
                </div>
              </div>

              <div>
                <Link to={postLink} className={`button is-rounded is-blue ${primary_category}`}>
                  <strong>Ler mais</strong>
                </Link>
              </div>
            </div>
          )
        })}
      </Slick>
    </div>
  )
}

export default BlogSlide
