const Helper = require('./helper')
const config = require('../../wp-lite/config')

module.exports = {
  formatServerData(data) {
    return Helper.formatWordpressObject(data, null);
  },

  getPostTypeConfig(postType) {
    return config.postTypes[postType]
  },

  clearInitialSchema(schema) {
    const data = {}

    Object.entries(schema).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value = []
      } else if (typeof value === 'object') {
        value = {}
      }

      data[key] = value
    })

    return data
  }
}
