import React, { Component } from "react"
import PreviewData from "../../plugins/gatsby-source-wp-lite/preview-data"

class PreviewPage extends Component {
  constructor(props) {
    super(props)

    const { mainDataKey, additionalData, postType } = props
    const postTypeConfig = PreviewData.getPostTypeConfig(postType)
    const { nodeType } = postTypeConfig
    const schema = PreviewData.clearInitialSchema(postTypeConfig.schema)

    const data = Object.assign({ [mainDataKey]: schema }, (additionalData || {}))
    const pageContext = { nodeType }

    this.state = {
      data,
      pageContext
    }
  }

  componentDidMount() {
    console.log("window.data", window.data)

    if (window.data) {
      const { mainDataKey, additionalData } = this.props
      const mainData = PreviewData.formatServerData(window.data)
      const newData = Object.assign({ [mainDataKey]: mainData }, (additionalData || {}))
      this.setState({ data: newData })
    }
  }

  render() {
    const Template = this.props.template
    const { data, pageContext } = this.state

    return (
      <Template data={data} pageContext={pageContext} />
    )
  }
}

export default PreviewPage
