module.exports = {
  finalSlashPath: true,
  events: {
    beforeCreateSidebarNode: null,
  },
  postTypes: {
    posts: {
      postType: "post",
      nodeType: "Post",
      limit: null,
      enabled: true,
      schema: postSchema(),
      relationMap: [
        {
          attribute: "category",
          nodeType: "Category"
        },
        {
          attribute: "post_tag",
          nodeType: "Tag"
        },
        {
          attribute: "primary_category",
          nodeType: "Category",
          single: true
        }
      ]
    },
    pages: {
      postType: "page",
      nodeType: "Page",
      limit: null,
      enabled: true,
      schema: pageSchema(),
      relationMap: []
    },
    main_pages: {
      postType: "main_page",
      nodeType: "MainPage",
      limit: null,
      enabled: true,
      schema: mainPageSchema(),
      relationMap: []
    },
    produtos: {
      postType: "produtos",
      nodeType: "Produto",
      limit: null,
      enabled: true,
      schema: produtosSchema(),
      relationMap: [
        {
          attribute: "categoria_produtos",
          nodeType: "CategoriaProduto"
        },
        {
          attribute: "stamp",
          nodeType: "Stamp"
        },
        {
          attribute: "primary_categoria_produtos",
          nodeType: "CategoriaProduto",
          single: true
        },
        {
          attribute: "acf.flavor_relation",
          nodeType: "Produto"
        },
      ]
    },
    banners: {
      postType: "banners",
      nodeType: "Banner",
      limit: null,
      enabled: true,
      schema: bannerSchema(),
      relationMap: []
    },
    awards: {
      postType: "awards",
      nodeType: "Award",
      limit: null,
      enabled: true,
      schema: awardSchema(),
      relationMap: []
    },
    faq: {
      postType: "faq",
      nodeType: "Faq",
      limit: null,
      enabled: true,
      schema: faqSchema(),
      relationMap: [
        {
          attribute: "faq_category",
          nodeType: "FaqCategory"
        },
      ]
    },
    news: {
      postType: "news",
      nodeType: "News",
      limit: null,
      enabled: true,
      schema: newsSchema(),
      relationMap: [
        {
          attribute: "news_category",
          nodeType: "NewsCategory"
        },
        {
          attribute: "news_tag",
          nodeType: "NewsTag"
        },
        {
          attribute: "primary_news_category",
          nodeType: "NewsCategory",
          single: true
        }
      ]
    },
    ebooks: {
      postType: "ebooks",
      nodeType: "Ebook",
      limit: null,
      enabled: true,
      schema: ebooksSchema(),
      relationMap: []
    },
  },
  taxonomyTypes: {
    categories: {
      nodeType: "Category",
      taxonomy: "category",
      schema: termSchema(),
      relationMap: [],
      enabled: true,
    },
    tags: {
      nodeType: "Tag",
      taxonomy: "post_tag",
      schema: termSchema(),
      relationMap: [],
      enabled: true,
    },
    categoria_produtos: {
      nodeType: "CategoriaProduto",
      taxonomy: "categoria_produtos",
      schema: termSchema(),
      relationMap: [],
      enabled: true,
    },
    stamp: {
      nodeType: "Stamp",
      taxonomy: "stamp",
      schema: stampSchema(),
      relationMap: [],
      enabled: true,
    },
    faq_category: {
      nodeType: "FaqCategory",
      taxonomy: "faq_category",
      schema: categoryFaqSchema(),
      relationMap: [],
      enabled: true,
    },
    news_category: {
      nodeType: "NewsCategory",
      taxonomy: "news_category",
      schema: termSchema(),
      relationMap: [],
      enabled: true,
    },
    news_tag: {
      nodeType: "NewsTag",
      taxonomy: "news_tag",
      schema: termSchema(),
      relationMap: [],
      enabled: true,
    },
  },
  postSinglePages: {
    blog: {
      nodeType: "Post",
      pathPrefix: "/blog/{{primary_category__slug}}",
      template: "./src/templates/blog-single.js",
      actionController: null,
      shortRedirectPath: null,
      enabled: true,
      contextAttributes: ["primary_category_id", "primary_category.slug"],
    },
    news: {
      nodeType: "News",
      pathPrefix: "/news",
      template: "./src/templates/news-single.js",
      actionController: null,
      shortRedirectPath: null,
      enabled: true,
      contextAttributes: ["primary_news_category_id"],
    },
    product: {
      nodeType: "Produto",
      pathPrefix: "/produtos/{{primary_categoria_produtos__slug}}",
      template: "./src/templates/product-single.js",
      actionController: null,
      shortRedirectPath: null,
      enabled: true,
      contextAttributes: ["primary_categoria_produtos_id", "primary_categoria_produtos.slug"],
    },
    page: {
      nodeType: "Page",
      pathPrefix: "/",
      template: "./src/templates/page.js",
      actionController: null,
      shortRedirectPath: null,
      enabled: true,
    },
  },
  postListPages: {
    blog: {
      nodeType: "Post",
      pathPrefix: "/blog",
      template: "./src/templates/blog.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    news: {
      nodeType: "News",
      pathPrefix: "/news",
      template: "./src/templates/news.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
  },
  taxonomyListPages: {
    faq_category: {
      attribute: "faq_category",
      taxonomyNodeType: "FaqCategory",
      postNodeType: "Faq",
      pathPrefix: "/fale-conosco",
      template: "./src/templates/fale-conosco.js",
      perPage: 999,
      actionController: null,
      enabled: true,
    },
    blog_categories: {
      attribute: "category",
      taxonomyNodeType: "Category",
      postNodeType: "Post",
      pathPrefix: "/blog",
      template: "./src/templates/blog-category.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    blog_tags: {
      attribute: "post_tag",
      taxonomyNodeType: "Tag",
      postNodeType: "Post",
      pathPrefix: "/blog/tag",
      template: "./src/templates/blog-tag.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    news_categories: {
      attribute: "news_category",
      taxonomyNodeType: "NewsCategory",
      postNodeType: "News",
      pathPrefix: "/news",
      template: "./src/templates/news-category.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    news_tags: {
      attribute: "news_tag",
      taxonomyNodeType: "NewsTag",
      postNodeType: "News",
      pathPrefix: "/news/tag",
      template: "./src/templates/news-tag.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    categoria_produtos: {
      attribute: "categoria_produtos",
      taxonomyNodeType: "CategoriaProduto",
      postNodeType: "Produto",
      pathPrefix: "/produtos",
      template: "./src/templates/product-category.js",
      perPage: 999,
      actionController: null,
      enabled: true,
    },
  },
  authorListPages: {
    blog: {
      postNodeType: "Post",
      postType: "post",
      pathPrefix: "/blog/author",
      template: "./src/templates/blog-author.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
    news: {
      postNodeType: "News",
      postType: "news",
      pathPrefix: "/news/author",
      template: "./src/templates/news-author.js",
      perPage: 8,
      actionController: null,
      enabled: true,
    },
  },
}

function postSchema() {
  return {
    ...basePostSchema(),
    thumbnail: imageSchema(),
    category: [0],
    post_tag: [0],
    primary_category: 0,
    acf: {
      spotlight: false,
      background_color: "",
      banner_image: imageSchema(),
    },
  }
}

function newsSchema() {
  return {
    ...basePostSchema(),
    thumbnail: imageSchema(),
    post_tag: [0],
    news_category: [0],
    primary_news_category: 0,
    acf: {
      spotlight: false,
      background_color: "",
      banner_image: imageSchema(),
    },
  }
}

function pageSchema() {
  return {
    ...basePostSchema()
  }
}

function mainPageSchema() {
  return {
    ...basePostSchema(),
    acf: {
      subtitulo_page: "",
      page_title: "",
      page_title_color: "",
      page_image: imageSchema(),
      destination_emails: "",
      cor_principal_page: "",
      contents: [
        {
          background_color: "",
          button_label: "",
          button_link: "",
          new_tab: false,
          image: imageSchema(),
          text: "",
          title: "",
        }
      ]
    }
  }
}

function produtosSchema() {
  return {
    ...basePostSchema(),
    thumbnail: imageSchema(),
    categoria_produtos: [0],
    stamp: [0],
    primary_categoria_produtos: 0,
    acf: {
      ingredients_text: "",
      ingredients_attention: "",
      where_buy: "",
      table: [
        {
          name: "",
          percentage: "",
          weight: "",
        }
      ],
      portion: "",
      flavor: "",
      flavor_relation: [0]
    }
  }
}

function bannerSchema() {
  return {
    ...basePostSchema(),
    acf: {
      background_color: "",
      theme: "",
      banner_description: "",
      banner_link: "",
      banner_title: "",
      button_label: "",
      banner_image: imageSchema(),
    }
  }
}

function awardSchema() {
  return {
    ...basePostSchema(),
    acf: {
      image: imageSchema()
    }
  }
}

function faqSchema() {
  return {
    ...basePostSchema(),
    faq_category: [0],
  }
}

function ebooksSchema() {
  return {
    ...basePostSchema(),
    acf: {
      file: imageSchema(),
      link: "",
    }
  }
}

function termSchema() {
  return {
    id: 0,
    term_id: 0,
    term_taxonomy_id: 0,
    name: "",
    slug: "",
    term_group: 0,
    taxonomy: "",
    description: "",
    parent: 0,
    count: 0,
    filter: "",
    yoast_title: "",
    yoast_json_ld: "",
    yoast_meta: [
      {
        name: "",
        property: "",
        content: ""
      }
    ]
  }
}

function stampSchema() {
  return {
    ...termSchema(),
    acf: {
      icon: imageSchema(),
    }
  }
}

function categoryFaqSchema() {
  return {
    ...termSchema(),
    acf: {
      icon_svg: "",
    }
  }
}

function basePostSchema() {
  return {
    id: 0,
    post_id: 0,
    author: 0,
    slug: "",
    date: "",
    date_gmt: "",
    content: "",
    title: "",
    excerpt: "",
    status: "",
    comment_status: "",
    ping_status: "",
    password: "",
    name: "",
    to_ping: "",
    pinged: "",
    modified: "",
    modified_gmt: "",
    content_filtered: "",
    parent: 0,
    guid: "",
    menu_order: 0,
    type: "",
    mime_type: "",
    comment_count: "",
    filter: "",
    yoast_title: "",
    yoast_json_ld: "",
    yoast_meta: [
      {
        name: "",
        property: "",
        content: ""
      }
    ]
  }
}

function imageSchema() {
  return {
    id: 0,
    title: "",
    filename: "",
    filesize: 0,
    url: "",
    link: "",
    alt: "",
    author: "",
    description: "",
    caption: "",
    name: "",
    status: "",
    uploaded_to: 0,
    date: "",
    modified: "",
    menu_order: 0,
    mime_type: "",
    type: "",
    subtype: "",
    icon: "",
    width: 0,
    height: 0,
    sizes: {
      _450_225_crop: "",
      _450_225_crop_width: 0,
      _450_225_crop_height: 0,
      _800_480_crop: "",
      _800_480_crop_width: 0,
      _800_480_crop_height: 0,
      _900_450_crop: "",
      _900_450_crop_width: 0,
      _900_450_crop_height: 0,
      _1920_400_crop: "",
      _1920_400_crop_width: 0,
      _1920_400_crop_height: 0,
      _1920_500_crop: "",
      _1920_500_crop_width: 0,
      _1920_500_crop_height: 0,
      _1920_850_crop: "",
      _1920_850_crop_width: 0,
      _1920_850_crop_height: 0,
      _1400_500_crop: "",
      _1400_500_crop_width: 0,
      _1400_500_crop_height: 0,
      _700_560_crop: "",
      _700_560_crop_width: 0,
      _700_560_crop_height: 0,
      thumbnail: "",
      thumbnail_width: 0,
      thumbnail_height: 0,
      medium: "",
      medium_width: 0,
      medium_height: 0,
      medium_large: "",
      medium_large_width: 0,
      medium_large_height: 0,
      large: "",
      large_width: 0,
      large_height: 0,
      post_thumbnail: "",
      post_thumbnail_width: 0,
      post_thumbnail_height: 0,
    }
  }
}
